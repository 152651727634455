body {
  background: #171927;
  color: #ffffff;
  // @include breakpoint-min(calc-rem(320)) {
  //         padding-top:98px;
  //     }
  //     @include breakpoint-min(calc-rem(992)) {
  //       padding-top:0px;
  //     }
  &.over-hide {
    overflow: hidden;
  }
}
$bottom-space: 250px;

$btn-bg: linear-gradient(to right, #a39ed1 0%, #456388 51%, #a39ed1 100%);
@mixin base-btn {
  background-image: $btn-bg;
  border-radius: 3px;
  font-family: Montserrat-Regular;
  font-size: calc-rem(15);
  color: #ffffff;
  display: inline-block;
  padding: 13px 39px;
  background-size: 200% auto;
  transition: 0.5s;
  text-decoration: none;
  &:hover {
    background-position: right center;
  }
}

a {
  text-decoration: none;
}
* {
  outline: none;
  :focus {
    outline: none;
  }
}
.container {
  @include breakpoint-max(calc-rem(991)) {
    max-width: 100%;
    padding: 0 25px;
  }

  @include breakpoint-min(calc-rem(992)) {
    padding: 0 15px;
  }
}
header.header {
  padding: 37px 0;
  // @include breakpoint-min(calc-rem(320)) {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   background: #171927;
  //   z-index: 3;
  // }
  .logo {
    position: relative;
    z-index: 2;
  }
  .top-section {
    position: relative;
    opacity: 1;
    transition: 0.5s;

    &.active {
      // background: #a39ed1;
      opacity: 1;
      z-index: 2;
      // &:after{
      //   content: "";
      //   bottom: -20px;
      //   background:  #a39ed1;
      //   height: 20px;
      //   width: 100%;
      //   position: absolute;
      //   z-index: 2;
      // }
      // &:before{
      //   content: "";
      //   top: -36px;
      //   background: #a39ed1;
      //   height: 40px;
      //   width: 100%;
      //   position: absolute;
      //   z-index: 2;
      // }
    }

    @include breakpoint-min(calc-rem(992)) {
      height: auto;
      background: transparent;
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    position: relative;
  }
}
img {
  max-width: 100%;
  max-height: 100%;
}
.pd-0 {
  padding: 0;
}
@mixin dot {
  height: 8px;
  width: 8px;
  background: #fff;
  display: inline-block;
  border-radius: 50%;
}
@mixin dot-animation {
  span {
    &:nth-child(1) i {
      margin-left: 11px;
      transition: 0.5s;
      &::before {
        transform: translate(11px, 11px);
      }
      &::after {
        transform: translate(11px, -11px);
      }
    }
    &:nth-child(2) {
      // animation: mymove 0.5s;
      // animation-fill-mode: forwards;
      // animation-delay: 0.25s;
      transform: scale(2.7);
      i {
        &::before {
          transform: translate(0px, 11px) scale(0);
        }
        &::after {
          transform: translate(0px, -11px) scale(0);
        }
      }
    }
    &:nth-child(3) i {
      margin-left: -11px;
      transition: 0.5s;
      &::before {
        transform: translate(-11px, 11px);
      }
      &::after {
        transform: translate(-11px, -11px);
      }
    }
  }
}
@keyframes mymove {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2.7);
  }
}
.mobile-menu {
  height: 30px;
  width: 30px;
  // align-items: center;
  // justify-content: space-between;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  z-index: 3;
  &:focus {
    outline: none;
  }
  &.active {
    @include dot-animation();
  }
  span {
    transform: scale(1);
    height: 30px;
    display: flex;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: 0;
    &:nth-child(1) {
      left: 0;
      i {
        transition: 0.5s;
      }
    }
    &:nth-child(2) {
      left: 11px;
    }
    &:nth-child(3) {
      left: 22px;
      i {
        transition: 0.5s;
      }
    }
    i {
      @include dot();
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        @include dot();
        transition: 0.5s;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        @include dot();
        transition: 0.5s;
      }
    }
  }
  @include breakpoint-min(calc-rem(320)) {
    // display: flex;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(0, -50%);
  }
  @include breakpoint-min(calc-rem(992)) {
    display: none;
  }

  &:hover {
    // @include dot-animation();
  }
}
.navigation {
  position: fixed;
  background: transparent;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0px;
  z-index: 1;
  padding: 25px;
  // -webkit-clip-path: circle(0px at 89% 49px);
  // clip-path: circle(0px at 89% 49px);
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;

  ul {
    list-style: none;
    display: block;
    // margin: 70px 0 0;
    padding: 0;
  }
  a {
    font-family: Montserrat-Regular;
    font-size: calc-rem(15);
    color: #ffffff;
    padding: 10px 0;
    margin: 0 15px;
    text-decoration: none;
    transition: 0.5s;
    position: relative;
    &:hover {
      color: #a39ed1;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      display: inline-block;
      height: 1em;
      width: 100%;
      border-bottom: 1px solid;
      margin-top: 10px;
      opacity: 0;
      -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
    }

    &:hover:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @include breakpoint-min(calc-rem(320)) {
    background: #a39ed1;
    ul {
      height: 60%;
      overflow: auto;
      padding: 0;
      margin: 0;
      position: fixed;
      top: 50%;
      width: 100%;
      transform: translate(0px, -40%);
    }
    &.active {
      visibility: visible;
      opacity: 1;
      display: block;
      //transition: 0.5s;
      // -webkit-clip-path: circle(1000px at 89% 49px);
      // clip-path: circle(1000px at 89% 49px);
      transition: all 0.5s;

      a {
        color: #fff;
        margin: 0 0 13px 0;
        transition: 0.5s;
      }
    }
    a {
      font-size: calc-rem(20);
      margin: 0 0 19px 0;
      padding: 0;
      display: inline-block;
    }
  }

  @include breakpoint-min(calc-rem(768)) {
    display: none;
    ul {
      transform: none;
    }
    &.active {
      ul {
        width: 250px;
        height: 392px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        overflow: auto;
      }
      a {
        font-size: calc-rem(30);
        margin: 0 0 19px 0;
      }
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    background: transparent;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      text-align: left;
      position: relative;
      transform: none;
    }
    a {
      font-family: Montserrat-Regular;
      font-size: calc-rem(15);
      color: #ffffff;
      padding: 10px 0;
      margin: 0 15px;
    }
    &.active {
      ul {
        width: auto;
        height: auto;
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
      }
    }
  }
}
.v-align-center {
  display: flex;
  align-items: center;
}
.contact {
  a {
    @include base-btn();
  }
  @include breakpoint-min(calc-rem(320)) {
    a {
      display: none;
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    a {
      display: inline-block;
    }
  }
}
.mobile-btn {
  display: inline-block;
  @include breakpoint-min(calc-rem(992)) {
    display: none;
  }
}
.banner {
  overflow: hidden;
  padding-bottom: 120px;
  .container {
    position: relative;
  }
  .banner-title {
    font-family: Montserrat-SemiBold;
    letter-spacing: 0;
    margin: 74px 0;
  }
  .banner-subtitle {
    opacity: 0.8;
    font-family: Montserrat-Regular;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 156px;
    max-width: 590px;
  }
  @include breakpoint-min(calc-rem(320)) {
    .banner-title {
      font-size: calc-rem(30);
      line-height: 40px;
      margin: 50px 0;
    }
    .banner-subtitle {
      margin-bottom: 90px;
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    overflow: visible;
    .banner-title {
      font-size: calc-rem(45);
      line-height: 50px;
      margin: 60px 0;
    }
    .banner-subtitle {
      margin-bottom: 120px;
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    .banner-title {
      font-size: calc-rem(75);
      line-height: 80px;
      margin: 74px 0;
    }
  }

  @include breakpoint-min(calc-rem(1200)) {
    overflow: visible;
  }
  .animate-svg {
    position: absolute;
    top: -80px;
    right: 0%;
    width: 60%;
    z-index: -1;
    display: flex;
    align-items: flex-start;
    &:after {
      content: "";
      position: absolute;
      background-image: linear-gradient(90deg, rgba(23, 25, 39, 0), rgba(23, 25, 39, 0), #171927);
      height: 100%;
      width: 100%;
    }
    svg {
      width: 49%;
    }

    svg.male {
      margin-top: 10px;
    }
    .male .path {
      stroke-dasharray: 500;
      stroke-dashoffset: 10;
      animation: dash 3s linear alternate;
      opacity: 0.5;
    }

    .male path:not(.path) {
      opacity: 0;
      animation: opacity 2s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
    }

    .female .path {
      stroke-dasharray: 2000;
      stroke-dashoffset: 0;
      animation: dash2 3s linear alternate;
      opacity: 0.5;
    }

    .female path:not(.path) {
      opacity: 0;
      animation: opacity 2s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
    }
    @keyframes opacity {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes dash {
      from {
        stroke-dashoffset: 2000;
      }
      to {
        stroke-dashoffset: 0;
      }
    }
    @keyframes dash2 {
      from {
        stroke-dashoffset: 2000;
      }
      to {
        stroke-dashoffset: 00;
      }
    }
    @include breakpoint-min(calc-rem(320)) {
      top: -40px;
      //  right: -17%;
      width: 80%;
    }
    @include breakpoint-min(calc-rem(768)) {
      top: -60px;
      //  right: -17%;
      width: 80%;
    }
    @include breakpoint-min(calc-rem(992)) {
      top: -120px;
      //  right: -15%;
      width: 65%;
    }
    @include breakpoint-min(calc-rem(1024)) {
      top: -70px;
      //  right: -15%;
      width: 75%;
    }
    @include breakpoint-min(calc-rem(1200)) {
      top: -70px;
      right: 0%;
      width: 60%;
    }
  }
}
.about-box {
  display: flex;
  align-items: center;
  padding: 0 -15px;
  background: #1e2239;
  // margin-bottom: 200px;
  flex-wrap: wrap;
  .about-canvas {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .about-text {
    @extend .about-canvas;
    font-family: Montserrat-Regular;
    font-size: calc-rem(22);
    line-height: 35px;
  }
  @include breakpoint-min(calc-rem(320)) {
    // margin-bottom: 130px;
    .about-canvas {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 30px;
    }
    .about-text {
      padding: 20px;
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    .about-canvas {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 0px;
    }
    .about-text {
      padding: 0px;
      text-align: left;
    }
  }
}
.information {
  padding-top: 50px;
  .listbox {
    .listing {
      margin-bottom: 50px;
      padding-left: 0px;
    }
    .list-title {
      font-size: calc-rem(18);
      letter-spacing: 0;
      display: block;
      margin-bottom: 20px;
      position: relative;
      // &:after {
      //   position: absolute;
      //   content: "";
      //   width: 87px;
      //   margin-left:20px;
      //   top: 15px;
      //   height: 1px;
      //   background: rgba(216, 216, 216, 0.2);
      // }
    }
    .list-text {
      font-size: calc-rem(16);
      letter-spacing: 0;
      line-height: 25px;
      flex: 1;
      width: 100%;
    }
    @include breakpoint-min(calc-rem(768)) {
      width: 75%;
    }
    @include breakpoint-min(calc-rem(992)) {
      width: 100%;
    }
  }

  @include breakpoint-min(calc-rem(320)) {
    .listbox {
      .listing {
        margin-bottom: 50px;
        padding-left: 50px;
        position: relative;
        &:before {
          position: absolute;
          content: "";
          width: 15px;
          left: 0;
          top: 15px;
          height: 1px;
          background: rgba(216, 216, 216, 0.2);
        }
      }
      .list-title {
        font-family: Montserrat-SemiBold;
        font-size: calc-rem(18);
        width: 250px;
        // &::after{
        //   display: none;
        // }
      }
      .list-text {
        font-family: Montserrat-Regular;
        font-size: calc-rem(15);
        line-height: 25px;
      }
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    padding-top: 130px;
    .listbox {
      .listing {
        display: flex;
        margin-bottom: 90px;
        padding-left: 132px;
        position: relative;
        &:before {
          width: 87px;
        }
      }
      .list-title {
        width: 310px;
        h3 {
          font-size: calc-rem(22);
          letter-spacing: 0;
          font-weight: normal;
          // &::after{
          //   display: none;
          // }
        }
      }
      .list-text {
        font-size: calc-rem(18);
        letter-spacing: 0;
        line-height: 29px;
        flex: 1;
        max-width: 522px;
      }
    }
  }
}
.marquee {
  // background: url(../../images/line-bg.svg) center no-repeat;
  // background-size: 100%;
  // padding: 142px 0 225px;
  // color: #fff;
}
.marquee-text {
  font-family: Montserrat-SemiBold;
  font-size: calc-rem(75);
  // padding: 0 60px;
}

.marquee {
  margin: 0px auto;
  // width: 600px; // or 100% inside a container
  background: url(../../images/lines.svg) right center no-repeat;
  padding: 125px 0px;
  background-size: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  @include breakpoint-min(calc-rem(992)) {
    padding: 170px 0;
    margin-bottom: 60px;
    background-size: 100%;
  }
  @include breakpoint-min(calc-rem(1366)) {
    padding: 192px 0;
    background-size: 100%;
  }
  @include breakpoint-min(calc-rem(1600)) {
    padding: 192px 0;
    background-size: 72%;
  }
}

.marquee__content {
  width: 300% !important;
  display: flex;

  animation: marquee 10s linear infinite forwards;
  @include breakpoint-min(calc-rem(992)) {
    animation: marquee 30s linear infinite forwards;
  }
}

.list-inline {
  display: flex;
  justify-content: space-around;
  width: 100%;

  /* reset list */
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  div {
    margin: 0 50px;
    font-family: Montserrat-SemiBold;
    font-size: calc-rem(50);
  }
  @include breakpoint-min(calc-rem(992)) {
    div {
      font-size: calc-rem(75);
    }
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-66.6%);
  }
}

.bg-1 {
  background: #1b3459;
}
.bg-2 {
  background: #111;
}
.bg-3 {
  background-image: linear-gradient(-128deg, #152725 39%, #152725 100%);
}
.bg-4 {
  background: #323a45;
}
.bg-5 {
  background: #a49dd0;
}
.bg-6 {
  background: #bec4e2;
}
.bg-7 {
  background: #8da9d9;
}
.bg-8 {
  background: #1e2239;
}
.bg-9 {
  background: #5b5853;
}
.bg-10 {
  background: #e4a17b;
}
.bg-11 {
  background: #000000;
}
.companies {
  padding: 30px 0 130px;

  .title {
    font-family: Montserrat-SemiBold;
    font-size: calc-rem(30);

    letter-spacing: 0;
    text-align: right;
    margin: 0px 0px 30px 0;
  }
  @include breakpoint-min(calc-rem(768)) {
    padding: 30px 0 180px;
    .title {
      margin: 0 0 80px 0;
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    padding: 30px 0 $bottom-space;
  }
  .com-list {
    display: flex;
  }
  .listing {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
  }
  .box {
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.5s;
    // height: 100%;
    @include breakpoint-min(calc-rem(992)) {
    margin-bottom: 100px;
    }
    a {
      display: block;
      text-decoration: none;
    
    @include breakpoint-min(calc-rem(1200)) {
      &:hover {
        
        .img-box img {
          transform: scale(1.1);
          transition: all 0.5s;
        }
        .logo-box {
         
          img{
            margin-left: 15px;
          }
        }
      }
    }
  }
    //  @media screen and (min-device-width: 1200px) and (max-device-width: 1366px) and (orientation : landscape){
    //   &:hover {
    //     transform: scale(1);
    //    // transition: all 0.5s;
    //     .img-box img {
    //       transform: scale(1);
    //     //  transition: all 0.5s;
    //     }
    //   }
    // }
  }
  .img-box {
    overflow: hidden;
    transition: all 0.5s;
    // min-height: 380px;
    // margin-bottom: 20px;
    // padding-top: 30px;
    margin-bottom: 35px;
    img {
      transition: all 0.5s;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    // &:hover{
    //     transition:all .5s;
    //     img{
    //         transform: scale(1.10);
    //         transition:all .5s;
    //     }
    // }
  }
  .logo-box {
    margin-bottom: 15px;
    min-height: 38px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: calc-rem(24);
    list-style: 24px;
    font-family: "Montserrat-Medium";
    img{
      margin-left: 10px;
      transition: .3s;
    }
  }
  .text-box {
    font-family: "Montserrat-Regular";
    letter-spacing: 0;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0;
    @include breakpoint-min(calc-rem(320)) {
      font-size: calc-rem(13);
    }
    @include breakpoint-min(calc-rem(768)) {
      font-size: calc-rem(16);
    }
  }
  .md-space {
    @include breakpoint-min(calc-rem(320)) {
      margin-bottom: 30px;
    }
    @include breakpoint-min(calc-rem(992)) {
      margin-bottom: 0px;
    }
  }
  .com-grid {
    visibility: hidden;
    -webkit-overflow-scrolling: touch;
    @include breakpoint-min(calc-rem(320)) {
      flex-wrap: nowrap;
      overflow: scroll;
      visibility: visible;
      margin: 0px -25px;
      padding: 0 10px 0 10px;
      -webkit-overflow-scrolling: touch;
      :hover > * {
        filter: grayscale(0);
      }
      .com-box {
        flex: 0 0 85%;
        max-width: 85%;
        // margin-bottom: 130px;
        overflow: hidden;
      }
    }
    @include breakpoint-min(calc-rem(768)) {
      .com-box {
        // margin-bottom: 180px;
        flex: 0 0 65%;
        max-width: 65%;
      }
    }
    @include breakpoint-min(calc-rem(992)) {
      flex-wrap: wrap;
      overflow: hidden;
      margin: 0px -15px;
      padding: 0;
      .com-box {
        flex: 0 0 50%;
        max-width: 50%;
        // margin-bottom: 30px;
      }
    }
  }
  
  .com-grid > * {
    visibility: visible;
  }
  // .com-grid > * {
  //   transition: filter 0.5s;
  // }
  // .com-grid:hover > * {
  //   filter: grayscale(1);
  //   transition: filter 0.5s;
  //   @include breakpoint-min(calc-rem(320)) {
  //     filter: grayscale(0);
  //     transition: filter 0.5s;
  //   }
  //   @include breakpoint-min(calc-rem(1200)) {
  //     filter: grayscale(1);
  //     transition: filter 0.5s;
  //   }
  // }
  // .com-grid > *:hover {
  //   filter: grayscale(0);
  // }
  .com-grid{
    .com-box{
     
      // @include breakpoint-min(calc-rem(1200)) {
      //   &.active{
      //   filter: grayscale(0);
      //   transition: filter 0.5s;
      //   }
      //   &.deactive{
      //     filter: grayscale(1);
      //     transition: filter 0.5s;
      //     }

      // }
     
    }
  }
}
.people {
  .title {
    font-family: Montserrat-SemiBold;
    font-size: calc-rem(30);

    letter-spacing: 0;
    margin-bottom: 50px;
  }
  .text-box {
    font-family: Montserrat-Regular;
    font-size: calc-rem(18);

    letter-spacing: 0;
    line-height: 35px;
    margin-bottom: 75px;
    max-width: 671px;
  }
  .list-box {
    // margin: 0 -15px;
    display: flex;
    .listing {
      // flex: 0 0 25%;
      // max-width: 25%;
      padding: 0 15px;
      transition: all 0.5s;
    }
    .img-box {
      margin-bottom: 18px;
      width: 250px;
      height: 350px;
      img {
        max-width: 250px;
      }
    }
    .name-box {
      font-family: Montserrat-SemiBold;
      font-size: calc-rem(20);

      letter-spacing: 0;
      margin-bottom: 5px;
    }
    .role-box {
      font-family: Montserrat-Regular;
      font-size: calc-rem(13);
      color: #878787;
      letter-spacing: 0;
    }
  }
  // .list-box {
  //   .listing:nth-child(2){
  //         .img-box{
  //           background:#a49dd0
  //         }
  //       }
  // }
  .master-list {
    display: none;
    height: 422px;
    @include breakpoint-min(calc-rem(1025)) {
      display: block;
    }
    @media screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (orientation: landscape) {
      display: none;
    }
  }
  .mobile-master-list {
    display: none;
    overflow: scroll;
    padding: 0 20px;
    height: 422px;
    -webkit-overflow-scrolling: touch;
    .listing:first-child {
      padding-left: 0px;
    }
    .listing:last-child {
      // padding-right:30px;
    }
    @media screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (orientation: landscape) {
      display: block;
    }
    @include breakpoint-max(calc-rem(1024)) {
      display: block;
    }
  }
  @include breakpoint-min(calc-rem(320)) {
    margin-bottom: 130px;
  }
  @include breakpoint-min(calc-rem(768)) {
    margin-bottom: 180px;
  }
  @include breakpoint-min(calc-rem(992)) {
    margin-bottom: $bottom-space;
  }
}

.testimonials {
  @include breakpoint-min(calc-rem(320)) {
    margin-bottom: 130px;
  }

  @include breakpoint-min(calc-rem(992)) {
    margin-bottom: $bottom-space;
  }
  .test-box {
    // width: 75%;
    .words {
      font-family: Montserrat-Regular;
      font-size: calc-rem(22);
      white-space: normal;
      letter-spacing: 0;
      line-height: 35px;
      margin-bottom: 30px;
      background: #171927;
    }
    .info-box {
      font-family: Montserrat-Regular;
      font-size: calc-rem(15);

      letter-spacing: 0;
      .xs-text {
        opacity: 0.5;
      }
    }
  }
  .test-grid {
    .test-list {
      position: relative;
    }
    #customNav {
      flex: 0 0 30%;
      max-width: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      button {
        background: none;
        border: none;
        font-size: 0;
        height: 40px;
        width: 40px;
        cursor: pointer;
      }
      .owl-prev {
        background: url(../../images/left-arrow.svg) no-repeat;
      }
      .owl-next {
        background: url(../../images/right-arrow.svg) no-repeat;
        margin-left: 50px;
      }
    }
  }
  .slick-list {
    @include breakpoint-min(calc-rem(320)) {
      width: 100%;
    }
    @include breakpoint-min(calc-rem(768)) {
      width: 70%;
    }
  }
  .slick-arrow {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 27px;
    background-size: 100%;
    &::before {
      display: none;
    }
  }

  @include breakpoint-min(calc-rem(320)) {
    .slick-arrow {
      top: auto;
      bottom: -70px;
    }
    .slick-next {
      right: 0%;
      background: url(../../images/right-arrow.svg);
    }
    .slick-prev {
      left: calc(100% - 120px);
      background: url(../../images/left-arrow.svg);
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    .slick-next {
      right: 0%;
      background: url(../../images/right-arrow.svg);
    }
    .slick-prev {
      left: calc(100% - 120px);
      background: url(../../images/left-arrow.svg);
    }
  }
}
.headquerters {
  @include breakpoint-min(calc-rem(320)) {
    margin-bottom: 130px;
  }
  @include breakpoint-min(calc-rem(992)) {
    margin-bottom: $bottom-space;
  }
  .img-box {
    position: relative;
    .grid-boxes {
      position: absolute;
      bottom: -50%;
      right: -37%;
      z-index: -1;
      width: 75%;
    }
  }
  .text-box {
    @include breakpoint-min(calc-rem(320)) {
      padding-left: 0px;
    }
    @include breakpoint-min(calc-rem(768)) {
      padding-left: 80px;
    }
    @include breakpoint-min(calc-rem(992)) {
      padding-left: 120px;
    }
  }
  .title {
    font-family: Montserrat-SemiBold;
    font-size: calc-rem(30);
    margin-bottom: 50px;
    img {
      margin-left: 10px;
    }
  }
  .text {
    font-family: Montserrat-Regular;
    font-size: calc-rem(18);
    line-height: 35px;
  }
  @include breakpoint-min(calc-rem(320)) {
    .grid-boxes {
      display: none;
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    .grid-boxes {
      display: block;
    }
  }
}
.image-gallery {
  .gallery {
    display: flex;
    align-items: center;
    .img-box {
      flex: 0 0 85%;
      padding: 0 15px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  @include breakpoint-min(calc-rem(320)) {
    margin-bottom: 130px;
  }
  @include breakpoint-min(calc-rem(992)) {
    margin-bottom: $bottom-space;
    .gallery {
      .img-box {
        flex: 0 0 70% !important;
      }
    }
  }
  @include breakpoint-min(calc-rem(1024)) {
    margin-bottom: $bottom-space;
    .gallery {
      .img-box {
        flex: 0 0 50% !important;
      }
    }
  }

  .gallery-box {
    display: none;
    position: relative;
    @include breakpoint-min(calc-rem(992)) {
      display: block;
    }
    @media screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (orientation: landscape) {
      display: none;
    }
  }

  .mobile-gallery-box {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    .img-box:last-child {
      // padding-right:30px;
    }
    @media screen and (min-device-width: 1281px) and (max-device-width: 1366px) and (orientation: landscape) {
      display: block;
    }
    @include breakpoint-min(calc-rem(992)) {
      display: none;
    }
  }
}

.connect {
  background: #2f365a;
  .title {
    font-family: Montserrat-SemiBold;
    font-size: calc-rem(30);
  }
  .sub-title {
    font-family: Montserrat-Regular;
    font-size: calc-rem(16);
    line-height: 25px;
    margin-bottom: 20px;
  }
  @include breakpoint-min(calc-rem(320)) {
    padding: 60px 0;
  }
  @include breakpoint-min(calc-rem(768)) {
    padding: 100px 0;
    .title {
      font-size: calc-rem(45);
    }
    .sub-title {
      font-size: calc-rem(22);
      line-height: 35px;
      margin-bottom: 30px;
    }
  }

  .btn-box {
    a {
      @include base-btn();
    }
  }
}
.footer {
  @include breakpoint-min(calc-rem(320)) {
    padding: 50px 0;
  }
  @include breakpoint-min(calc-rem(768)) {
    padding: 80px 0;
  }
  .listing {
    @include breakpoint-max(calc-rem(767)) {
      margin-bottom: 20px !important;
    }
  }
  .list-box {
    display: flex;
    flex-wrap: wrap;

    font-family: Montserrat-Medium;
    font-size: calc-rem(16);
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.13);
    margin-bottom: 50px;
    .list-head {
      color: #5463a4;

      @include breakpoint-min(calc-rem(320)) {
        margin-bottom: 10px;
      }
      @include breakpoint-min(calc-rem(768)) {
        margin-bottom: 20px;
      }
    }
    .list-text {
      color: #ffffff;
      a {
        color: #ffffff;
        text-decoration: none;
      }
    }
    .right-align {
      display: flex;
      @include breakpoint-min(calc-rem(320)) {
        justify-content: flex-start;
      }
      @include breakpoint-min(calc-rem(768)) {
        justify-content: flex-end;
      }
    }
    .center-align {
      @include breakpoint-min(calc-rem(320)) {
        max-width: 100%;
        margin: 0 auto;
      }
      @include breakpoint-min(calc-rem(768)) {
        max-width: 365px;
        margin: 0 auto;
      }
    }
    .social-icon {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
      }
      li {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
      a {
        display: inline-block;
      }
    }
  }
  .bottom-box {
    opacity: 0.5;
    font-family: Montserrat-Regular;
    font-size: calc-rem(12);
    color: #ffffff;
  }
  .footer-links {
    display: flex;
    @include breakpoint-min(calc-rem(320)) {
      justify-content: flex-start;
      margin: 10px 0 0 0;
    }
    @include breakpoint-min(calc-rem(768)) {
      justify-content: flex-end;
      margin: 0;
    }
    a {
      @extend .bottom-box;
      display: inline-block;
      padding: 0 10px 0 0;
      margin: 0 10px 0 0;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      &:last-child {
        border: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}
#return-to-top {
  background: url(../../images/uparrow.svg) no-repeat;
  background-size: 100%;
  position: fixed;
  bottom: -90px;
  right: 20px;
  text-decoration: none;
  transition: bottom 0.5s ease-in;
  height: 40px;
  width: 40px;
}
#return-to-top.active {
  bottom: 20px;
  transition: bottom 0.5s ease-in, background-position 0.5s;
  // transition: all 0.3s cubic-bezier(0,-0.72, 1, 2.67);
}
.partners {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1e2239;
  padding: 127px 0;
  margin-bottom: 250px;
  
  .partnersBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
   
    .title {
      font-family: Montserrat-SemiBold;
      font-size: calc-rem(25);
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      flex: 0 0 100%;
      padding: 0 25px;
      margin-bottom: 30px;
      @include breakpoint-min(calc-rem(768)) {
        text-align: left;
          flex: 1;
          margin-right: 83px;
          font-size: calc-rem(30);
          margin-bottom: 0px;
        
      }
    }
    .partnersList {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 100%;
      @include breakpoint-min(calc-rem(768)) {
        flex: 1;
      }
      a {
        display: inline-block;
        margin-right: 70px;
        &:last-child {
          margin-right: 0;
        }
        img {
          
          max-height: 86px;
          max-width: 110px;
          @include breakpoint-min(calc-rem(768)) {
            max-height: 96px;
          max-width: 130px;
          }
        }
      }
    }
    @include breakpoint-min(calc-rem(768)) {}
  }
}
